<template>
  <div>
    <eventsList :key="$store.state.business.unsaved.events.length" />
    <eventsAdd />
    <div
      class="saveBtn flix-text-danger flix-html-strong"
      v-if="error"
      :key="error"
    >
      {{ error }}
    </div>

    <saveBtn
      v-if="$store.state.business.unsaved.events.length"
      class="saveBtn"
      :callback="setSave"
    />
  </div>
</template>
<script>
import eventsPlugin from "@/plugin/check/eventpicker.js";
import def from "@/components/business/default/index.vue";
export default {
  extends: def,
  data() {
    return {
      error: false
    };
  },
  components: {
    eventsList() {
      return import("./eventsList");
    },
    eventsAdd() {
      return import("./eventsAdd");
    }
  },
  methods: {
    setSave() {
      this.checkDublicateEvents();

      if (this.error) {
        return false;
      }
      this.setReorder();
      if (
        typeof this.$store.state.business.assistent.ID === "undefined" ||
        !this.$store.state.business.assistent.ID
      ) {
        this.nextStep();
      } else {
        this.finalStep();
      }
    },
    nextStep() {
      this.$router.push({ name: "settings" });
    },
    checkDublicateEvents() {
      this.error = false;

      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));

      var eP = new eventsPlugin();
      eP.parent = this;
      eP.data = data;
      eP.checkSameDate();
      eP.checkTimeRange();

      if (eP.result.error) {
        this.error = eP.result.todo[0].msg;
      }
    },
    setReorder() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));

      var eP = new eventsPlugin();
      eP.data = data;
      eP.calculateEventsOrder();
      data.events = eP.data.events;
      data.max_applicants = eP.data.max_applicants;

      this.$store.commit("business/prefetch", data);
      this.$store.commit("business/update", data);
    }
  }
};
</script>
<style lang="sass" scoped>
.saveBtn
  margin-top: 20px
</style>
